import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout";


// markup
const NotFoundPage = ({location}) => {
  return (
    <Layout location={location}>
      <section className="container mx-auto my-24">
        <h1 className={"text-7xl font-bold text-center py-24"}>Cette page n'existe pas.</h1>
          <p className={"mb-10"}>Malheureusement nous n'avons pas pu trouver cette page.</p>
          <Link className={"underline underline-offset-2"} to={"/"}>Revenir à l'accueil</Link>
      </section>
    </Layout>
  )
}

export default NotFoundPage